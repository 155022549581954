import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Form, Image, Button, Spinner } from 'react-bootstrap'
import { CountryDropdown } from 'react-country-region-selector';
import PhoneInput from 'react-phone-input-2'
import p from '../images/MISSIONS.jpg'
import { PaystackButton } from 'react-paystack'
import Select from 'react-select';
import swal from '@sweetalert/with-react'
import Message from '../components/Message'
import 'react-phone-input-2/lib/style.css'
import { submitToApi, parseDate, currencyChecker, getBankDetails, getTotalCostOfBiblesInSelectedCurrency } from '../services/Helpers';
import { loadStripe } from '@stripe/stripe-js'
import Calendar from 'react-calendar';
import { Countries } from '../services/Countries'
import dotenv from 'dotenv'
import Checks from '../components/Checks';
import { useFlutterwave, closePaymentModal } from 'flutterwave-react-v3';
import { useParams } from "react-router-dom";
import { supportedCurrencies } from '../services/SupportedCurrencies';

dotenv.config();

const BibleMissions = () => {
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [country, setCountry] = useState('')
    const [phone, setPhone] = useState('')
    const [city, setCity] = useState(null)
    const [specifiedCities, setSpecifiedCities] = useState(null)
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)
    const [loadingSpin, setLoadingSpin] = useState(false)
    const [donateLater, setDonateLater] = useState(false)
    const [date, setDate] = useState(new Date())
    const [timeline, setTimeline] = useState('')
    const [currency, setCurrency] = useState('')
    const [show, setShow] = useState(false)
    const [amount, setAmount] = useState('')

    const { referrer } = useParams();
    const [validReferrer, setValidReferrer] = useState(false);
    const [referrerBankDetails, setReferrerBankDetails] = useState({});
    const [customerSelectedAmount, setCustomerSelectedAmount] = useState('');

    const countryCode = (countryName) => {
        return Countries.find(({ Code, Name }) => Name.toLowerCase() === countryName.toLowerCase())?.Code.toLowerCase()
    }

    const countryString = countryCode(country)

    const nairaAmount = city?.value === 'Specify' ? specifiedCities?.value * 4200 : city?.value * 4200;

    const dollarAmount = city?.value === 'Specify' ? specifiedCities?.value * 20 : city?.value * 20;

    const euroAmount = city?.value === 'Specify' ? specifiedCities?.value * Math.ceil(18.97) : city?.value *  Math.ceil(18.97);

    const poundsAmount = city?.value === 'Specify' ? specifiedCities?.value * Math.ceil(16.31 ) : city?.value * Math.ceil(16.31) 

    const randAmount = city?.value === 'Specify' ? specifiedCities?.value * Math.ceil(316.36) : city?.value * Math.ceil(316.36);

    const cadAmount = city?.value === 'Specify' ? specifiedCities?.value * Math.ceil(25.86) : city?.value * Math.ceil(25.86)


    const handleCurrency=(selectedOptions)=>{
     setCurrency(selectedOptions)
      }

    const getAmount = () => {
        switch (currency) {
            case "Pound":
                setAmount(`£${poundsAmount}`)
                break;
            case "Dollar":
                setAmount(`$${dollarAmount}`)
                break;
            case "Naira":
                setAmount(`₦${nairaAmount}`)
                break;
            case "CAD":
                setAmount(`CAD${cadAmount}`)
                break;
            case "Euro":
                setAmount(`€${euroAmount}`)
                break;
            case "Rand":
                setAmount(`R${randAmount}`)
                break;
            default: return amount
        }
    }

    function getCurrencyAmount(){
        switch(currency.value){
            case "NGN":
                 return nairaAmount
            case "ZAR":
              return randAmount
          case "USD":
               return dollarAmount
          case "EUR":
              return euroAmount
          case "GBP":
              return poundsAmount
          case "CAD":
              return cadAmount
            default:
                return 0
          }
    }

    useEffect(() => {
        if (!referrer) return;

        getBankDetails(`https://rhapsody-bible.herokuapp.com/api/referrer/get-bank-details/${referrer}`).then(res => {
            if (res.status === 404){
                return setValidReferrer(false);
            }
            setValidReferrer(true);
            setReferrerBankDetails(res.data);
        }).catch(err => {
            console.log("An error occurred while trying to fetch referrer bank details");
            setValidReferrer(false);
        })

    }, [referrer]);

    useEffect(() => {
        const query = new URLSearchParams(window.location.search);
        if (query.get("success")) {
            swal("success", " Your donation has been recieved and your details have been saved", "success");
        }
        if (query.get("canceled")) {
            swal(
                "failed", "Donation canceled, please try again", "error"
            );
        }

        getAmount()
    })

    useEffect(() => {
        
        if ((!city) || (!currency.value)) return;

        setCustomerSelectedAmount(`${currency.value} ${getTotalCostOfBiblesInSelectedCurrency(currency.value, city.value)}`)
        setLoadingSpin(false)

    }, [city, currency])

    const getOptions = () => {
        // const options = [];
        // for (var i = 1; i < 11; i++) {
        //     const obj = {
        //         value: i,
        //         label: i === 1 ? `${i} City` : `${i} Cities`
        //     }
        //     options.push(obj)
        // }
        // return [...options, { value: 'Specify', label: 'Specify' }]
        return [
            {value:5,label:"Bible Missions To A Family - 5 Bibles"},
            {value:50,label:"Bible Missions To A Street - 50 Bibles"},
            {value:100,label:"Bible Missions To A Community - 100 Bibles"},
            {value:250,label:"Bible Missions To A City - 250 Bibles"},
            {value:1000,label:"Bible Missions To A Nation - 1,000 Bibles"},
            { value: 'Specify', label: 'Specify' }
        ]
    };

    const getCurrency = () =>{
        return [
            {value:"NGN",label:"Naira"},
            {value:"USD",label:"Dollars"}, 
            {value:"ZAR",label:"Rand"},
            {value:"EUR",label:"Euros"},
            {value:"GBP",label:"Pounds"},
            {value:"CAD",label:"Canadian Dollar"}
        ]
    }

  


    const handleOptions = (selectedOptions) => {
        setCity(selectedOptions)
    };

   

    const clearState = () => {
        setFirstName('')
        setLastName('')
        setEmail('')
        setCity(null)
        setSpecifiedCities(null)
        setCountry('')
        setPhone('')
        setError('')
        setLoading(false)
    };

    const stripePromise = loadStripe("pk_live_GTa5tVhyJYNk1LaBtgXM7ZUl");

    // const handleStripe = async (e) => {
    //     e.preventDefault();
    //     setLoading(true)
    //     const stripe = await stripePromise;
    //     const res = await submitToApi('https://rhapsody-bible.herokuapp.com/api/biblemissions', {
    //         email,
    //         firstName,
    //         lastName,
    //         city: city?.value === 'Specify' ? specifiedCities?.value?.toString() : city?.value?.toString(),
    //         country,
    //         phone,
    //         timeline
    //     })

    //     if (res?.data.message) {
    //         setLoading(false)
    //         return setError(res.data.message)
    //     }

    //     const result = await stripe.redirectToCheckout({
    //         sessionId: res.data.stripeId,
    //     });



    //     if (result?.error) {
    //         swal("Error", "An error occured. Please try again later", "error")
    //     }
    // }


  
    const submitForm = async (e) => {
        e.preventDefault();
        const res = await submitToApi('https://rhapsody-bible.herokuapp.com/api/biblemissions', {
            email,
            firstName,
            lastName,
            city: city?.value === 'Specify' ? specifiedCities?.value?.toString() : city?.value?.toString(),
            country,
            phone,
            timeline,
            referrer: referrer ? referrer : "",
        })
        if (res?.data.message) {
            return setError(res.data.message)
        } else {
            swal("Successful!", "Your details have been saved", "success")
        }
    }

 
    // const publicKey = process.env.REACT_APP_PAYSTACK_PUBLIC_KEY

    // const componentProps = {
    //     email,
    //     amount: amount * 100,
    //     metadata: {
    //         firstName,
    //         lastName,
    //         phone,
    //     },
    //     publicKey,
    //     text: `Donate Now In ₦ (${amount ? amount : ''})`,
    //     onSuccess: () => swal("Payment Succesful!", "Your payment has been proccessed successfully", "success"),
    //     onClose: () => clearState(),
    // }

    const options = getOptions();

    const currencyOptions = getCurrency()

    const config = {
        public_key: "FLWPUBK-d7680be9103ea527a15b6114640bc87b-X",
        tx_ref: Date.now(),
        amount: customerSelectedAmount === '' ? getCurrencyAmount() : Number(customerSelectedAmount.split(" ")[1]),
        currency:currency.value,
        payment_options: "card,mobilemoney,ussd",
        // specified redirect URL
        redirect_url: "https://rhapsodybible.org/biblemissions/?success=true",
        customer: {
          email: email,
          phonenumber: phone,
          name: firstName,
        },
        customizations: {
          title: "Rhapsody Bible",
          description: "Biblemandate Donation",
          logo: "https://rhapsodybible.org/wp-content/uploads/2020/03/Rhapsody-Bible-logo.png",
        },
      };
    const handleFlutterPayment = useFlutterwave(config);
async function handleFlutterwave(e){
    e.preventDefault();
    setLoadingSpin(true);
    const res = await submitToApi('https://rhapsody-bible.herokuapp.com/api/biblemissions', {
            email,
            firstName,
            lastName,
            city: city?.value === 'Specify' ? specifiedCities?.value?.toString() : city?.value?.toString(),
            country,
            phone,
            timeline,
            referrer: referrer ? referrer : "",
        })
        if (res?.data.message) {
            setLoadingSpin(false);
            return setError(res.data.message)
        }
    handleFlutterPayment({
        callback: (response) => {
          console.log(response);
          setLoadingSpin(false);
        //  window.location.href = "/";
          closePaymentModal(); // this will close the modal programmatically
        },
        onClose: () => {
            setLoadingSpin(false);
        },
      });
      // you can also like localStorage.removeItem('Token');
  
}



    const handleDonateLater = () => {
        !donateLater ? setDonateLater(true) : setDonateLater(false)
        setDate('')
    }

    const handleBankTransferButton = (e) => {
        e.preventDefault()
        setShow(true)
    }

    const handleClose = async (e) => {
        e.preventDefault()
        await getAmount()
        setShow(false)
        swal({
            title: "Account Details",
            text: currencyChecker(currency, amount, referrerBankDetails),
            button: {
                text: "Done",
            }
        }).then(async () => {
            const res = await submitToApi('https://rhapsody-bible.herokuapp.com/api/biblemissions', {
                email,
                firstName,
                lastName,
                city: city?.value === 'Specify' ? specifiedCities?.value?.toString() : city?.value?.toString(),
                country,
                timeline,
                phone,
                referrer: referrer ? referrer : "",
            })
            if (res?.data.message) {
                return setError(res.data.message)
            } else {
                swal("Successful!", "Your details have been saved", "success")
                clearState()
            }
        }).catch(err => swal("Error", err.message))
    }

    const handleSubmitDetailsButton = async () => {
        const res = await submitToApi('https://rhapsody-bible.herokuapp.com/api/biblemissions', {
            email,
            firstName,
            lastName,
            city: city?.value === 'Specify' ? specifiedCities?.value?.toString() : city?.value?.toString(),
            country,
            phone,
            timeline,
            donateLater: parseDate(date.toString()),
            referrer: referrer ? referrer : "",
        })
        if (res?.data.message) {
            return setError(res.data.message)
        } else {
            swal("Successful!", "Your details have been saved", "success")
        }
    }

    return (
        ( (!referrer) || (referrer && validReferrer) ) &&
        <>
        <Container className="py-4">
            <Checks handleClose={handleClose} show={show} setShow={setShow} currency={currency} setCurrency={setCurrency} />
            <Row>

                <Col md={6}>
                    <h4> Project Bible Missions to 1 Million Communities</h4>
                    <p>Jesus’ final command to his disciples in the Bible is to “go and make disciples of all nations” (Matthew 28:19). The responsibility of the Great Commission is for every disciple of Jesus, as Christians, we are called to spread God’s word to the ends of the world — whether that is just outside our door or across the globe.
This is what we are doing with the Rhapsody Bible Missions; we are meeting the spiritual needs of many around the world by bringing the knowledge of God’s word to them in their cities and nations through organized Bible Mission outreaches.</p>
                    <p>    Join us to reach people in different nations where there is little or no access to the Bible through strategic Bible Missions, thereby increasing the influence of God’s word around the world.</p>
                    <div>

                        <Image src={p} rounded fluid />
                    </div>
                </Col>

                <Col className="my-3" md={6}>
                    <h3 style={{ 'fontSize': '1.5rem' }}>Adopt a City for Bible Missions today!</h3>
                    {error && <Message variant='danger'>{error}</Message>}
                    <Form onSubmit={submitForm}>
                        <Form.Group controlId='firstName' className='half__Width__2'>
                            <Form.Control
                                type='firstName'
                                value={firstName}
                                placeholder='First Name'
                                onChange={e => setFirstName(e.target.value)}
                            />
                        </Form.Group>

                        <Form.Group controlId='lastName' className='half__Width'>
                            <Form.Control
                                type='lastName'
                                value={lastName}
                                placeholder='Last Name'
                                onChange={e => setLastName(e.target.value)}
                            />
                        </Form.Group>

                        <Form.Group controlId='email'>
                            <Form.Control
                                type='email'
                                value={email}
                                placeholder='Email'
                                onChange={e => setEmail(e.target.value)}
                            />
                        </Form.Group>

                        <Form.Group controlId='country' className='half__Width__2'>
                            <CountryDropdown
                                className="form-control"
                                value={country}
                                placeholder='Country'
                                onChange={(val) => setCountry(val)}
                            />
                        </Form.Group>

                        <Form.Group controlId='phone' className="mb-3 half__Width">
                            <PhoneInput country={countryString} inputStyle={{ "width": "100%" }} placeholder='Phone' value={phone} onChange={phone => setPhone(phone)} />
                        </Form.Group>

                        <Form.Group controlId='city'>
                            <Select
                                value={city}
                                onChange={handleOptions}
                                options={options}
                                placeholder='Donate Now'
                                required
                            />
                        </Form.Group>

                       

                        {city?.value === 'Specify' && (
                            <Form.Group controlId='city'>
                                <Form.Label>Specify number of cities</Form.Label>
                                <Form.Control
                                    type='number'
                                    min='0'
                                    value={specifiedCities?.value}
                                    placeholder='Enter Number'
                                    onChange={e => setSpecifiedCities({ value: e.target.value, label: "cities" })}
                                />
                            </Form.Group>
                        )
                        }

                        <Form.Group controlId="currency">
                            <Select
                                value={currency}
                                onChange={handleCurrency}
                                options={supportedCurrencies}
                                placeholder={"Currency"}
                                required
                            />
                        </Form.Group>

                        <Form.Group controlId="timeline">
                            <Form.Control
                                as="select"
                                value={timeline}
                                onChange={(e) => setTimeline(e.target.value)}
                            >
                                <option>How often do you want to donate?</option>
                                <option>One-Off</option>
                                <option>Monthly</option>
                                <option>Quarterly</option>
                                <option>Yearly</option>
                            </Form.Control>
                        </Form.Group>

                        {donateLater && (
                            <div>
                                <Form.Group>
                                    <Form.Check
                                        type='checkbox'
                                        label="Donate Now?"
                                        onClick={handleDonateLater}
                                    />
                                </Form.Group>
                                <Calendar
                                    className='my-3 mx-auto'
                                    value={date}
                                    onChange={setDate}
                                />
                            </div>
                        )}
                        {
                            donateLater ?
                                (
                                    <Button className='px-5 button' onClick={handleSubmitDetailsButton}>Submit Details</Button>

                                ) :
                                (
                                    <div>
                                        {/* <PaystackButton
                                          className="btn btn-success button " {...componentProps}
                                        /> */}

                                    <Button className='px-5 button button2' role='link' onClick={handleFlutterwave} style={{ 'display': 'block' }}>
                                            {loadingSpin ? (
                                                <Spinner animation="border" role="status">
                                                    <span className="sr-only">Loading...</span>
                                                </Spinner>
                                            ) : `Donate ${customerSelectedAmount} Now`}
                                        </Button>


                                        {/* <Button className='px-5 button button2' role='link' onClick={handleStripe} style={{ 'display': 'block' }}>
                                            {loading ? (
                                                <Spinner animation="border" role="status">
                                                    <span className="sr-only">Loading...</span>
                                                </Spinner>
                                            ) : `Donate Now $ (${dollarAmount ? dollarAmount : ''})`}
                                        </Button> */}

                                        <Button className='px-5 button' onClick={handleBankTransferButton}>
                                            {`Donate Now With Bank Transfer`}
                                        </Button>

                                        <Button className='px-5 button' onClick={handleDonateLater}>
                                            Donate Later
                                        </Button>
                                    </div>
                                )
                        }
                    </Form>
                </Col>
            </Row>
        </Container>
        </>
    )
}

export default BibleMissions
