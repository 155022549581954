import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { useUserContext } from "../../contexts/UserContext";
import { getAuthUserStatus, getDonations, getDonationsForAdmin } from "../../services/oneDollarServices";
import { Button, Container, Row, Table } from "react-bootstrap";
import styles from './styles.module.css';
import { AiOutlineCopy, AiOutlineDownload } from 'react-icons/ai';
import swal from "@sweetalert/with-react";
import Select from 'react-select';
import { CSVLink } from "react-csv";
import { useRef } from "react";

const Donations = () => {
    const navigate = useNavigate();
    const { user, setUser } = useUserContext();
    const [ pageLoaded, setPageLoaded ] = useState(false);
    const [ donationsMadeToUser, setDonationsMadeToUser ] = useState([]);
    const [ donationsMadeLoaded, setDonationsMadeLoaded ] = useState(false);
    const [ btnDisabled, setBtnDisabled ] = useState(false);
    const [ usersWithLink, setUsersWithLink] = useState([]);
    const [ currentUsername, setCurrentUserName ] = useState(null);
    const [ allDonationsMade, setAllDonationsMade ] = useState([]);
    const [ dataToDownload, setDataToDownload ] = useState([]);
    const csvLinkRef = useRef();

    useEffect(() => {
        document.title = "Donations | 1Dollar"

        const authToken = sessionStorage.getItem('accessToken')
        if (!user && !authToken) return navigate("/login")

        if (user) return setPageLoaded(true)

        getAuthUserStatus(authToken).then(res => {
            // console.log(res.data.profile);
            setPageLoaded(true)
            setUser(res.data.profile);

        }).catch(err => {
            console.log(err);
            
            sessionStorage.clear();
            setPageLoaded(true);
            setUser(null);

            navigate("/login")
        })

    }, [])

    useEffect(() => {

        if (!user || donationsMadeLoaded) return

        const authToken = sessionStorage.getItem('accessToken')

        if (user.isAdminUser) {
            getDonationsForAdmin(authToken).then(res => {
                setDonationsMadeLoaded(true);
                setAllDonationsMade(res.data.donations);
                setUsersWithLink(res.data.users.filter(user => !user.isAdminUser).map(user => ({ ...user, label: user.username, value: user.username })));
            }).catch(err => {
                setDonationsMadeLoaded(true);
            })

            return
        }

        getDonations(authToken).then(res => {
            setDonationsMadeLoaded(true);
            setDonationsMadeToUser(res.data);
        }).catch(err => {
            setDonationsMadeLoaded(true);
        })

    }, [user])

    useEffect(() => {
        if (!currentUsername) return

        setDonationsMadeToUser(allDonationsMade.filter(donation => donation.usernameUsed === currentUsername.value));
    }, [currentUsername])

    useEffect(() => {

        setDataToDownload(
            donationsMadeToUser.map(donation => {
                const copyOfDonation = { ...donation }
                delete copyOfDonation._id;
                delete copyOfDonation.__v;
                delete copyOfDonation.updatedAt;
                delete copyOfDonation.createdAt;

                return copyOfDonation
            })
        )

    }, [donationsMadeToUser])

    const handleCopyLink = () => {
        console.log(user);
        navigator.clipboard.writeText(`${window.location.origin}/1dollar/${user.username}`)
    }

    const handleLogout = () => {
        setBtnDisabled(true);

        sessionStorage.clear();
        setUser(null);

        swal("Success", "Successfully logged out", "success");

        navigate("/login")
    }

    const handleDownloadAsCSV = () => {
        if (donationsMadeToUser.length < 1) return swal("Info", "No data to download", "info");

        csvLinkRef.current.link.click();
    }

    if (!pageLoaded || !user) return <>Please wait...</>

    return <>
        <Container>
            <Row>
                <img className={styles.logo} src="https://nowthatyouarebornagain.org/wp-content/uploads/2021/03/NTYABA-LOGO-small.png" alt="logo" />
            </Row>
            <Row className={styles.top__Content}>
                <div>
                    <h4>Hello {user?.username},</h4>
                    <span>
                        {
                            user?.isAdminUser ? 
                            `Users that have created links : ${usersWithLink.length}` :
                            "List of users that have used your unique payment link"
                        }
                    </span>
                </div>
                
                {
                    !user.isAdminUser ? <div className={styles.copy__Item} onClick={handleCopyLink}>
                        <span>Copy username</span>
                        <AiOutlineCopy />
                    </div> :
                    <div className={styles.copy__Item} onClick={handleDownloadAsCSV}>
                        <span>Download as CSV</span>
                        <AiOutlineDownload />
                    </div>
                }
            </Row>
            {
                user.isAdminUser && <Row className={styles.select__user__Wrapper}>
                    <span>Select user</span>
                    <Select
                        value={currentUsername}
                        onChange={(val) => setCurrentUserName(val)}
                        options={usersWithLink}
                        placeholder={"Select user"}
                        className={styles.select__user}
                    />
                </Row>
            }
            <Row className={styles.main__Content}>
                {
                    !donationsMadeLoaded ? <>please wait...</> :
                    donationsMadeToUser.length < 1 ? <div className={styles.no__Content}>
                        <img 
                            alt="illustration"
                            src="https://img.freepik.com/free-vector/no-data-concept-illustration_114360-536.jpg?size=626&ext=jpg&ga=GA1.1.225976907.1673277028&semt=country_rows_v1" 
                        />
                        <span>
                            {
                                user?.isAdminUser ? 
                                !currentUsername ?
                                    "Select a user to see donations made to their username"
                                :
                                    "No donations have been made yet with this username"
                                :
                                    "No donations have been made yet with your username"
                            }
                        </span>
                    </div> :
                    <>
                        <Table striped responsive>
                            <thead>
                                <tr>
                                    <th>Email</th>
                                    <th>First name</th>
                                    <th>Last name</th>
                                    <th>Country</th>
                                    <th>Amount Donated</th>
                                    <th>Currency</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    React.Children.toArray(donationsMadeToUser.map(donation => {
                                        return <tr>
                                            <td>{donation.email}</td>
                                            <td>{donation.firstName}</td>
                                            <td>{donation.lastName}</td>
                                            <td>{donation.country}</td>
                                            <td>{donation.amountDonated}</td>
                                            <td>{donation.currency}</td>
                                        </tr>
                                    }))
                                }
                            </tbody>
                        </Table>
                    </>
                }
            </Row>
            
        </Container>

        <div
            className={styles.logout__Content}
        >
            <Button 
                className={`px-5 button`}
                style={{ backgroundColor: "#EC4D0F", borderColor: '#EC4D0F' }}
                onClick={handleLogout}
                disabled={btnDisabled}
            >
                {
                    btnDisabled ? 
                    "Please wait..." :
                    "Logout"
                }
            </Button>
        </div>

        <CSVLink 
            style={{ display: 'none' }} 
            ref={csvLinkRef} 
            data={dataToDownload}
            filename="donations.csv"
        >
            Download me
        </CSVLink>;
        
    </>
}

export default Donations;
