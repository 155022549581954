import React from 'react';
import { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import styles from './styles.module.css';
import swal from "@sweetalert/with-react";
import { validateEmail } from '../../utils/util';
import { joinOneDollarCampaign } from "../../services/oneDollarServices";
import { Link } from "react-router-dom";


const GenerateNewLinkScreen = () => {

    const [ details, setDetails ] = useState(initialState);
    const [ btnDisabled, setBtnDisabled ] = useState(false);


    useEffect(() => {
        document.title = "Generate Link | 1Dollar"
    }, [])

    const handleDetailChange = (name, value) => {
        setDetails((prevDetails) => {
            return {
                ...prevDetails,
                [name]: value,
            }
        })
    }
    const generateAlertContent = (userNameEntered) => (
        <div>
          Your link is now active and ready to be shared!
                    <br />
          ✓ To see who has signed up with your link <a href="/login">click here</a>
          <br />
          ✓ You’ll also receive an email with details on how to log in to your account
        </div>
      );

    const handleGenerateBtnClick = async () =>  {
        if (details.email.length < 1) return swal("Info", "Please enter an email", "info");
        if (!validateEmail(details.email)) return swal("Error", "Please enter a valid email", "error");
        if (details.username.length < 1) return swal("Info", "Please enter a username", "info");
        if (details.password.length < 1) return swal("Info", "Please enter a password", "info");

        // console.log(details);
        setBtnDisabled(true);

        try {
            const data = (await joinOneDollarCampaign(details)).data;
            console.log(data);
            const userNameEntered = details.username;
            
            swal({
                title: "Congratulations!",
                content: generateAlertContent(details.username), // Pass the generated content here
                icon: "success",
                buttons: ['Close', 'Copy link & Start making donation'],

            }).then((copyBtnClicked) => {
                if (copyBtnClicked) {
                    navigator.clipboard.writeText(`${window.location.origin}/1dollar/${userNameEntered}`)
                }

            })
            setDetails(initialState);

        } catch (error) {
            swal("Error", error.response ? error.response.data : error.message, "error");
        }

        setBtnDisabled(false);
    }
    
    return <>
        <Container className="py-4">
            <div className={styles.wrapper}>
                <Row>
                    <Col className="my-3" md={12}>
                        <img className={styles.logo} src="https://nowthatyouarebornagain.org/wp-content/uploads/2021/03/NTYABA-LOGO-small.png" alt="logo" />
                        <h3>Create your unique payment link</h3>
                        <Form.Group controlId='email'>
                            <Form.Control
                                type='email'
                                value={details.email}
                                placeholder='Email'
                                name="email"
                                onChange={({ target }) => handleDetailChange(target.name, target.value)}
                            />
                        </Form.Group>
                        <Form.Group controlId='username'>
                            <Form.Control
                                type='username'
                                value={details.username}
                                placeholder='Unique username'
                                name="username"
                                onChange={({ target }) => handleDetailChange(target.name, target.value)}
                            />
                        </Form.Group>
                        <Form.Group controlId='password'>
                            <Form.Control
                                type='password'
                                value={details.password}
                                placeholder='Password'
                                name="password"
                                onChange={({ target }) => handleDetailChange(target.name, target.value)}
                            />
                        </Form.Group>
                        <Form.Group controlId='zone'>
                            <Form.Control
                                type='text'
                                value={details.zone}
                                placeholder='Zone'
                                name="zone"
                                onChange={({ target }) => handleDetailChange(target.name, target.value)}
                            />
                        </Form.Group>
                        <Form.Group controlId='church'>
                            <Form.Control
                                type='text'
                                value={details.church}
                                placeholder='Church'
                                name="church"
                                onChange={({ target }) => handleDetailChange(target.name, target.value)}
                            />
                        </Form.Group>
                        <p className={styles.login__Link__Item}>Already have your unique link? 
                            <Link className={styles.login__Link} to={'/login'}>
                                Login now
                            </Link>
                        </p>
                        <Button 
                            className='px-5 button' 
                            style={{ backgroundColor: "#EC4D0F", borderColor: '#EC4D0F' }}
                            onClick={handleGenerateBtnClick}
                            disabled={btnDisabled}
                        >
                            {
                                btnDisabled ? 
                                "Please wait..." :
                                "Create"
                            }
                        </Button>
                    </Col>
                </Row>
            </div>
        </Container>
    </>
}

const initialState = {
    email: "",
    username: "",
    password: "",
    zone: "",
    church: "",
}

export default GenerateNewLinkScreen;
