import React from 'react';
import { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import styles from './styles.module.css';
import { validateEmail } from "../../utils/util";
import swal from "@sweetalert/with-react";
import { getAuthUserStatus, loginUser } from "../../services/oneDollarServices";
import { useUserContext } from "../../contexts/UserContext"
import { Link, useNavigate } from "react-router-dom";

const LoginScreen = () => {

    const [ details, setDetails ] = useState({
        email: '',
        password: ''
    });
    const [ btnDisabled, setBtnDisabled ] = useState(false);
    const [ pageLoaded, setPageLoaded ] = useState(false);
    const { user, setUser } = useUserContext();
    const navigate = useNavigate();

    useEffect(() => {
        document.title = "Login | 1Dollar"

        if (user) return navigate("/donations")

        const authToken = sessionStorage.getItem('accessToken')
        if (!authToken) return setPageLoaded(true)

        getAuthUserStatus(authToken).then(res => {
            // console.log(res.data.profile);
            setUser(res.data.profile);
            setPageLoaded(true);
            navigate("/donations");
            
        }).catch(err => {
            console.log(err);
            
            sessionStorage.clear();
            setUser(null);

            setPageLoaded(true);
        })
    }, [])
    

    const handleDetailChange = (name, value) => {
        setDetails((prevDetails) => {
            return {
                ...prevDetails,
                [name]: value,
            }
        })
    }
    
    const handleLoginBtnClick = async () => {
        if (details.email.length < 1) return swal("Info", "Please enter a email", "info");
        if (details.password.length < 1) return swal("Info", "Please enter a password", "info");

        if (!validateEmail(details.email)) return swal("Info", "Please enter a valid email", "info");
        setBtnDisabled(true);

        try {
            const res = (await loginUser(details)).data;
            // console.log(res.accessToken, res.user);
            sessionStorage.setItem('accessToken', res.accessToken);
            setUser(res.user)

            swal("Success", "Successfully logged in", "success");
            setBtnDisabled(false);
            navigate("/donations");

        } catch (error) {
            setBtnDisabled(false);
            swal("Error", error.response ? error.response.data : error.message, "error");
        }
    }

    if (!pageLoaded) return <>Please wait...</>
    
    return <>
        <Container className="py-4">
            <div className={styles.wrapper}>
                <Row>
                    <Col className="my-3" md={12}>
                        <img className={styles.logo} src="https://nowthatyouarebornagain.org/wp-content/uploads/2021/03/NTYABA-LOGO-small.png" alt="logo" />
                        <h3>Login</h3>
                        <span>View users that have used your unique payment link</span>
                        <br />
                        <br />

                        <Form.Group controlId='email'>
                            <Form.Control
                                type='email'
                                value={details.email}
                                placeholder='Email'
                                name="email"
                                onChange={({ target }) => handleDetailChange(target.name, target.value)}
                            />
                        </Form.Group>
                        <Form.Group controlId='password'>
                            <Form.Control
                                type='password'
                                value={details.password}
                                placeholder='Password'
                                name="password"
                                onChange={({ target }) => handleDetailChange(target.name, target.value)}
                            />
                        </Form.Group>

                        <p className={styles.login__Link__Item}>Not generated your unique link yet? 
                            <Link className={styles.login__Link} to={'/generate'}>
                                Generate now
                            </Link>
                        </p>

                        <Button 
                            className='px-5 button' 
                            style={{ backgroundColor: "#EC4D0F", borderColor: '#EC4D0F' }}
                            onClick={handleLoginBtnClick}
                            disabled={btnDisabled}
                        >
                                Login
                        </Button>
                    </Col>
                </Row>
            </div>
        </Container>
    </>
}

export default LoginScreen;
