import React from 'react';
import { Route, Routes, BrowserRouter as Router } from "react-router-dom";
import Footer from "./components/Footer";
import Header from "./components/Header";
import BibleMissions from "./screens/BibleMissions";
import GenerateNewLinkScreen from "./screens/GenerateNewLinkScreen/GenerateNewLinkScreen";
import LoginScreen from "./screens/Login/Login";
import OneDollarDonationFormScreen from "./screens/OneDollarDonationForm/OneDollarDonationForm";
import Donations from "./screens/Donations/Donations";


const App = () => {
  return (
    <>
      <Router>
          <Routes>
            {/* <Route path="/biblemissions" element={
              <>
                <Header />
                  <main>
                    <BibleMissions />
                  </main>
                <Footer />
              </>
            } >
              <Route path=":referrer" element={
                <>
                  <Header />
                  <main>
                    <BibleMissions />
                  </main>
                </>
              } />
            </Route> */}
            <Route path="/generate" element={
              <GenerateNewLinkScreen />
            } />
            <Route path="/login" element={
              <LoginScreen />
            } />
            <Route path="/1dollar/:username" element={
              <OneDollarDonationFormScreen />
            } />
            <Route path="/donations" element={
              <Donations />
            } />
          </Routes>
      </Router>  
    </>
  );
}

export default App;
