export const validateEmail = (email) => {
    //eslint-disable-next-line
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
}

export const bankAccounts = [
    {
        type: "USA(Dollar)",
        bankName: "Wells Fargo",
        address: "599 W Pioneer PKWY Grand Prairie TX 75051",
        accountName: "LWP LLC",
        accountNumber: 1409721246,
        routingNumber: 111900659,
        wireTransferDomestic: 121000248,
    },
    {
        type: "POUNDS ACCOUNT",
        bankName: "Barclays Bank UK",
        accountName: " Believers Loveworld Limited (LWPM Account)",
        accountNumber: 70091650,
        sortCode: 20-89-15,
        iban: 'GB68BUKB20891570091650',
        swift: 'BUKBGB22',
    },
    {
        type: "NIGERIA ACCOUNT",
        bankName: "Sterling Bank Plc",
        accountName: "LWPLTD - Now That You Are Born Again",
        accountNumber: '0086869303',
    },
    {
        type: "EURO ACCOUNT",
        bankName: "Barclays Bank UK",
        accountName: "Believers Loveworld Limited",
        accountNumber: 43263222,
        sortCode:- 20-89-15,
        iban: "GB36 BARC 2089 1543 2632 22",
        swift: "BARCGB22",
    },
    {
        type: "SOUTH AFRICA (RAND)",
        bankName: "Standard Bank",
        accountName: "Loveworld Publishing Pty",
        accountNumber: "420283706",
    },
    {
        type: "CANADA ACCOUNT - (CAN.DOLLAR)",
        bankName: "TD Canada Trust ",
        accountName: "Loveworld Publishing Inc.",
        accountNumber: " 19995277593",
    }
]