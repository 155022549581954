import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './bootstrap.min.css'
import 'bootstrap/dist/css/bootstrap.css';
import 'react-calendar/dist/Calendar.css'
import App from './App';
import UserContextProvider from './contexts/UserContext';


ReactDOM.render(
    <UserContextProvider>
      <App />
    </UserContextProvider>,
  document.getElementById('root')
);
